import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Card,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import Header from "../header/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import FooterSection from "../footer/FooterSection";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress } from "@mui/material";

import "../../App.css";
import "../subject/Subject.css";
import "../private-class/PrivateClass.css";
import "../overview/Overview.css";
import "../responsive/Responsive.css";

import clock from "../../assets/images/clock.svg";
import calendar from "../../assets/images/calendar.svg";
import image162 from "../../assets/images//image162.png";
import attendance from "../../assets/images/attendance.svg";
import studyMaterial from "../../assets/images/studyMaterial.png";
import watchRecording from "../../assets/images/watchRecording.png";
import classNotes from "../../assets/images/classNotes.png";
import assignmentImg from "../../assets/images/assignment_img.png";
import download from "../../assets/images/gray_download.svg";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import VideoModal from "../common-components/VideoModal";
import Advertisement from "../common-components/Advertisement";
import useTranslations from "../../custom-hooks/useTranslations";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { encryptData } from "../../services/encryptDecrypt";
import generateAgoraToken from "../../services/AgoraTokenService";
import { ZoomImageContainer } from "../common-components/ZoomImageContainer";
import { getCourses, GetGroupClassSchedule } from "../../actions/coursesAction";
import { axiosPost } from "../../services/api";
import { API_BASE_URL } from "../../config/apiConfig";
import { getStudentProfile } from "../../actions/studentProfileAction";
import FeedbackModal from "../common-components/FeedbackModal";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CircularProgressWithLabel(props) {
  return (
    <Box
      className="custom-progress"
      sx={{
        position: "relative",
        display: "inline-flex",
        backgroundColor: "#fff",
        borderRadius: "50%",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
        }}
        {...props}
        thickness={6}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const CoursesSchedules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classId, tab } = useParams();
  const decodedId = atob(classId);
  const translations = useTranslations();
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState({});
  const [filterSessions, setFilterSessions] = useState([]);
  const [selectedDate, setSelectedDate] = useState("0");
  const [selectedMonth, setSelectedMonth] = useState("0");
  const [feedbackModal, setFeedbackModal] = useState(
    sessionStorage.getItem("StudentSessionEnd") ? true : false
  );
  const [value, setValue] = useState(tab ? Number(atob(tab)) : 0);
  const [sessionFeedBack, setSessionFeedBack] = useState({
    TutorRating: "",
    FeedbackMessage: [],
  });
  const [isApiCalling, setIsApiCalling] = useState(true);

  const translation = {
    ...translations[8]?.PrivateClassDashboard,
    ...translations[12]?.ClassDetail,
    ...translations[13]?.Payment,
    ...translations[19]?.PaymentDetails,
    ...translations[21]?.CommonFrontend,
    ...translations[23]?.TutorRegistration,
    ...translations[26]?.QuickCall,
    ...translations[31]?.Wallet,
    ...translations[36]?.PracticeTest,
    ...translations[38]?.GroupCourses,
  };

  const privateTutorSessionID = sessionStorage.getItem("SessionID");
  const profileInfo = Cookies.get("ProfileInfo")
    ? JSON.parse(Cookies.get("ProfileInfo"))
    : null;
  const courses = useSelector(({ coursesReducer }) => coursesReducer.courses);
  const groupClass = useSelector(
    ({ GetGroupClassScheduleReducer }) =>
      GetGroupClassScheduleReducer.groupClass
  );  

  const handleBack = () => {
    navigate(-1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const viewRecordedVideo = (cls) => {
    setIsVideoModal(true);
    setSelectedClass(cls);
  };

  const handleClose = () => {
    setIsVideoModal(false);
  };

  const monthList = useMemo(() => {
    const allMonths =
      groupClass?.Sessions?.map((session) => {
        const date = new Date(session.SessionDate);
        return date.toLocaleString("default", { month: "long" });
      }) || [];
      
      const uniqueMonths = [...new Set(allMonths)];

    return uniqueMonths;
  }, [groupClass]);

  const monthsObj = useMemo(() => {
    const allDates = groupClass?.Sessions?.reduce((acc, session) => {
        const date = new Date(session.SessionDate);
        const sessionMonth = date.toLocaleString("default", { month: "long" });
        const sessionDay = date.toLocaleString("default", { day: "numeric" });

        if (!acc[sessionMonth]) {
            acc[sessionMonth] = [];
        }

        // Ensure sessionDay is unique
        if (!acc[sessionMonth].includes(sessionDay)) {
            acc[sessionMonth].push(sessionDay);
        }

        return acc;
    }, {}); // Provide initial value as an empty object

    return allDates; // Explicitly return the result
}, [groupClass]);


  const selectedCourse = useMemo(() => {
    return courses?.MyCourses?.find((course) => course.ID === decodedId);
  }, [courses, decodedId]);

  const onChangeFeedback = (name, value) => {
    setSessionFeedBack((prev) => {
      if (name === "FeedbackMessage") {
        const updatedFeedBack = prev?.FeedbackMessage?.includes(value)
          ? prev.FeedbackMessage.filter((item) => item !== value)
          : [...prev.FeedbackMessage, value];

        return {
          ...prev,
          FeedbackMessage: updatedFeedBack,
        };
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onSubmitFeedback = async () => {
    sessionStorage.removeItem("StudentSessionEnd");
    setFeedbackModal(false);
    try {
      const data = {
        SessionID: privateTutorSessionID,
        Ratings: sessionFeedBack.TutorRating,
        FeedbackID: sessionFeedBack.FeedbackMessage,
      };
      await axiosPost(`${API_BASE_URL}/GroupClassSessionfeedback`, data);
      setSessionFeedBack({
        TutorRating: "",
        FeedbackMessage: [],
      });
      dispatch(getStudentProfile());
      sessionStorage.removeItem("StudentRequest");
      sessionStorage.removeItem("TutorResponse");
      sessionStorage.removeItem("SessionID");
      localStorage.removeItem("CallDuration");
      localStorage.removeItem("CallType");
    } catch (error) {
      console.error("An error occurred while saving feedback:", error);
    }
  };

  const joinSession = (cls) => {
    const tutorResponse = {
      TutorID: cls?.TutorID,
      TutorName: cls?.TutorName,
      TutorImage: cls?.TutorPic,
      StudentID: profileInfo?.ID,
      StudentName: profileInfo?.UserName,
      StudentImage: profileInfo?.ProfilePictureUrl,
    };
    sessionStorage.setItem("pathName", window.location.pathname);
    sessionStorage.setItem("TutorResponse", JSON.stringify(tutorResponse));
    generateAgoraToken(
      profileInfo?.UID,
      2,
      `channel${cls?.ID}${selectedCourse?.ID}`
    ).then((res) => {
      localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
      localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
      navigate(
        `/course/${encryptData(Number(selectedCourse?.ID))}/${encryptData(
          Number(cls.ID)
        )}`
      );
    });
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    const filterSession = groupClass?.Sessions?.filter((item) => {
        const itemDate = new Date(item.SessionDate);
    
        return (
            itemDate.toLocaleString("default", { month: "long" }) === selectedMonth &&
            itemDate.getDate() == date
        );
    });
    setSelectedDate(date);
    setFilterSessions(filterSession)
};

const handleMonthChange = (event) => {
    const month = event.target.value;

    const filterSession = groupClass?.Sessions?.filter((item) => {
        const itemDate = new Date(item.SessionDate);
    
        return (
            itemDate.toLocaleString("default", { month: "long" }) === month 
        );
    });
    setSelectedDate("0");
    setSelectedMonth(month);
    setFilterSessions(filterSession)
};

  useEffect(() => {
    dispatch(getCourses())
  }, []);

  useEffect(() => {
    const topicIds = selectedCourse?.Subject?.map((topic) => topic.TopicID);
    if (topicIds) {
      dispatch(GetGroupClassSchedule({ TopicIDs: topicIds, CourseID: decodedId }))
        .then((response) => {
          console.log("response",response);
          
          if (response.StatusCode === 200) {
            setIsApiCalling(false);
            const sortedSessions = response.Data.Sessions.sort((a, b) => new Date(a.SessionDate) - new Date(b.SessionDate))
            const nextSession = sortedSessions.find((session) => new Date(session.SessionDate).toLocaleDateString() >= new Date().toLocaleDateString())
            || sortedSessions[0];
            const month = new Date(nextSession.SessionDate).toLocaleString("default", { month: "long" });
            const day = new Date(nextSession.SessionDate).toLocaleString("default", { day: "numeric" });            
            const nextSessions = sortedSessions.filter((item) => new Date(item.SessionDate).toDateString() === new Date(nextSession.SessionDate).toDateString())
            setSelectedDate(day);
            setSelectedMonth(month);
            setFilterSessions(nextSessions);
          }
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  }, [selectedCourse]);
  
  
  return (
    <div>
      <Header />
      <Box className="quikCallBanner">
        <Box
          className="OuterContainer"
          sx={{ border: "none", borderRadius: "0px" }}
        >
          <Typography variant="h1">
            {translation?.Hi} {profileInfo?.UserName},{" "}
            <Typography variant="div"> {translation?.WelcomeYasa}</Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        className="OuterContainer containerShape"
        sx={{ background: "#EAF4FF", padding: "30px" }}
      >
        <Box className="SectionIn">
          <Box className="BackBtn">
            <Button
              className="backBtn"
              variant="text"
              sx={{ mb: 2 }}
              startIcon={
                localStorage.getItem("I18N_LANGUAGE") === "en" ? (
                  <ArrowBackIcon />
                ) : (
                  <ArrowForward />
                )
              }
              onClick={handleBack}
            >
              {translation?.BackBtn}
            </Button>
          </Box>
          <Box sx={{ width: "100%" }} className="tab-wrapper">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {/* <Tab label={translation?.Overview} {...a11yProps(0)} /> */}
                <Tab label={translation?.Schedule} {...a11yProps(0)} />
                <Tab label={translation?.Documents} {...a11yProps(1)} />
                <Tab label={translation?.Payments} {...a11yProps(2)} />
              </Tabs>
            </Box>
            {isApiCalling ? (
              <Box
                className="outerCard oCenter"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box className="custom-panel-wrapper">
                <CustomTabPanel value={value} index={0}>
                  {/* <Box className="p2-pbox">
                                      <Box className="p2-pbox-inner">
                                          <Box className="p2-child-inner">
                                              <img src={attendance} />
                                              <Typography variant='h4' sx={{ fontSize: '20px', fontWeight: '600', color: '#555555' }}>{translation?.Attendance}</Typography>
                                          </Box>
                                          <CircularProgressWithLabel value={0} />
                                      </Box>
                                  </Box> */}
                  <Card
                    elevation={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      padding: 2,
                      backgroundColor: "#fff0f0",
                      borderRadius: 2,
                    }}
                  >
                    {/* Icon and Text Section */}
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        component="img"
                        src={image162}
                        alt="Attendance Icon"
                        sx={{ width: 40, height: 40 }}
                      />
                      <Typography variant="h6" fontWeight="bold">
                        {translation?.gcAttendance}
                      </Typography>
                    </Box>

                    {/* Circular Progress Section */}
                    <Box position="relative" display="inline-flex">
                      <CircularProgressWithLabel
                        value={parseFloat(groupClass?.Attendance)}
                      />
                    </Box>
                  </Card>

                  <Box
                    className="white-card"
                    sx={{ marginBottom: "16px", marginTop: "16px" }}
                  >
                    <Box className="filterHead" sx={{ mb: "0" }}>
                      <Box>
                        <FormControl sx={{ marginRight: "10px" }}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedDate}
                            onChange={handleDateChange}
                          >
                            <MenuItem value="0" disabled>
                              {translation?.SelectDate}
                            </MenuItem>
                            {monthsObj[selectedMonth]?.map((month, index) => (
                              <MenuItem value={month} key={index}>
                                {month}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ marginRight: "10px" }}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                          >
                            <MenuItem value="0" disabled>
                              {translation?.SelectMonth}
                            </MenuItem>
                            {monthList?.map((item, index) => {
                              return (
                                <MenuItem value={item} key={index}>
                                  {translation[item]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>

                  {filterSessions?.length === 0 && (
                    <Box
                      className="white-card"
                      sx={{
                        marginBottom: "16px",
                        marginTop: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px", // Adjust height as needed
                      }}
                    >
                      {translation?.NoDataFound}
                    </Box>
                  )}

                  {filterSessions?.map((session, index) => {
                    const sessionDate = new Date(session.SessionDate);

                    return (
                      <Box
                        className="white-card"
                        key={index}
                        sx={{ marginBottom: "16px" }}
                      >
                        <Box className="dflx">
                          {/* Left Section */}
                          <Box className="left_sect">
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "20px",
                                fontWeight: "600",
                                fontFamily: '"Rajdhani", sans-serif;',
                                marginBottom: "8px",
                                color: "#005DB8",
                              }}
                            >
                              Session {index + 1}: {session.TopicName}
                            </Typography>
                            <Box
                              className="oc_headwrap"
                              sx={{
                                padding: "0",
                                display: "flex",
                                gap: "10px",
                                marginBottom: "0 !important",
                              }}
                            >
                              <Box className="oc_headleft">
                                <List
                                  sx={{
                                    padding: "0",
                                    display: "flex",
                                    gap: "15px",
                                    width: "100%",
                                  }}
                                >
                                  <ListItem>
                                    <img src={clock} alt="Clock Icon" />
                                    {sessionDate.toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </ListItem>
                                  <ListItem>
                                    <img src={calendar} alt="Calendar Icon" />
                                    {sessionDate.toLocaleDateString()}
                                  </ListItem>
                                </List>
                              </Box>
                            </Box>
                          </Box>

                          {/* Right Section */}
                          <Box className="right_sect">
                            <Box className="lbl_btn_flx">
                              {session?.SessionStatusID === "3" && (
                                <Typography
                                  variant="span"
                                  className="lbl_tag lt-green"
                                >
                                  {translation?.CompletedSession}
                                </Typography>
                              )}
                              {session?.SessionStatusID === "1" && (
                                <Typography
                                  variant="span"
                                  className="lbl_tag lt_purple"
                                >
                                  {translation?.Schedule}
                                </Typography>
                              )}
                              {session?.SessionStatusID === "4" && (
                                <Typography
                                  variant="span"
                                  className="lbl_tag lt_red"
                                >
                                  {translation?.Cancelled}
                                </Typography>
                              )}
                              {session.SessionStatusID === "2" && (
                                <>
                                  <Typography
                                    variant="span"
                                    className="lbl_tag lt_yellow"
                                  >
                                    {translation?.OnGoing}
                                  </Typography>
                                  <Button
                                    sx={{
                                      fontSize: "14px",
                                      color: "#fff",
                                      backgroundColor: "#005DB8",
                                      minWidth: "80px",
                                      height: "28px",
                                      textTransform: "capitalize",
                                      borderRadius: "4px",
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                        border: "1px solid #005DB8",
                                        color: "#005DB8",
                                      },
                                    }}
                                    onClick={() => joinSession(session)}
                                  >
                                    {translation?.gcJoinNow}
                                  </Button>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>

                        {/* Study Material, Notes, and Other Options */}
                        {session?.SessionStatusID === "3" && (
                          <>
                            <Box sx={{ marginTop: "5px" }}>
                              <Rating name="read-only" value={5} readOnly />
                            </Box>
                            <Box sx={{ marginTop: "24px" }}>
                              <Box className="boxes-wrapper">
                                  <Box className="box_wrp" sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '15%', minHeight: '0' }} onClick={() => setValue(1)}>
                                      <img src={studyMaterial} sx={{ marginBotton: '8px' }} />
                                      {/* <Typography variant="h4" sx={{ fontSize: '20px', fontSize: '18px', fontWeight: '500', color: '#000' }}>{translation?.StudyMaterial}</Typography> */}
                                  </Box>
                             
                                { session?.SessionRecordURL &&
                                    <Box className="box_wrp" sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '15%', minHeight: '0' }} onClick={() => viewRecordedVideo(session)}>
                                        <img src={watchRecording} />
                                        {/* <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: '500', color: '#000' }}>{translation?.WatchRecording}</Typography> */}
                                    </Box>
                                }

                                   { session?.SessionNotes &&
                                    <Box className="box_wrp" sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '15%', minHeight: '0' }} onClick={() => window.open(session?.SessionNotes, '_blank')}>
                                        <img src={classNotes} />
                                        {/* <Typography variant="h4" sx={{ fontSize: '20px', fontWeight: '500', color: '#000' }}>{translation?.ClassNote}</Typography> */}
                                    </Box>
                                }
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    );
                  })}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  {groupClass?.Document?.length > 0 ? (
                    groupClass?.Document?.map((note) => (
                      <Box className="white-card document_wrap" key={note?.ID}>
                        <Box className="left_doc">
                          <Box className="docImgSample">
                            {/* <Box className="docImg_wrap">
                                                          <img src={note?.ImageURL} alt="Document Preview" />
                                                      </Box> */}
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "#000000",
                                }}
                              >
                                {`${translation?.Session} ${
                                  groupClass?.Sessions?.findIndex(
                                    (Session) => Session?.ID === note?.SessionID
                                  ) + 1
                                } : ${note?.Value} `}
                              </Typography>
                              <Box className="doc_time">
                                {/* <Box>
                                        <img src={clock} alt="Clock Icon" />
                                        <Typography>{new Date(note?.DateTime).toLocaleTimeString()}</Typography>
                                    </Box>
                                    <Box>
                                        <img src={calendar} alt="Calendar Icon" />
                                        <Typography>{new Date(note?.DateTime).toLocaleDateString('en-US')}</Typography>
                                    </Box> */}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          className="right_doc"
                          onClick={() =>
                            window.open(note?.DocumentURL, "_blank")
                          }
                        >
                          <img src={download} alt="Download Icon" />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#727272",
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      {translation?.NoDataAvailable}
                    </Typography>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Box className="payment_wrapper">
                    {groupClass?.Payments?.length > 0 ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            fontWeight: "500",
                            color: "#000000",
                            marginBottom: "16px",
                            textTransform: "capitalize",
                          }}
                        >
                          {translation?.PaymentDetails}
                        </Typography>
                        <Box>
                          {groupClass?.Payments?.map((session, index) => (
                            <Box
                              className="white-card"
                              key={index}
                              sx={{ marginBottom: "16px" }}
                            >
                              <Box className="dflx">
                                <Box className="left_sect">
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "500",
                                      color: "#000",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    {session?.Value}
                                  </Typography>
                                  {/* 
                                                        { session?.PaymentDetails[0]?.PaymentRefID && 
                                                            <Typography 
                                                                variant='h5' 
                                                                sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1', color: '#000', marginBottom: '8px', textTransform: 'capitalize' }}
                                                            >
                                                                {translation?.ReferenceIdNo}. - {session?.PaymentDetails[0]?.PaymentRefID}
                                                            </Typography>
                                                        } 
                                                        */}

                                  <Box
                                    className="oc_headwrap"
                                    sx={{
                                      padding: "0",
                                      display: "flex",
                                      gap: "10px",
                                      marginBottom: "0 !important",
                                    }}
                                  >
                                    <Box className="oc_headleft">
                                      <List
                                        sx={{
                                          padding: "0",
                                          display: "flex",
                                          gap: "15px",
                                          width: "100%",
                                        }}
                                      >
                                        <ListItem>
                                          <CalendarTodayIcon />
                                          {translation?.PaymentType} :{" "}
                                          {session?.PaymentType}
                                        </ListItem>
                                      </List>
                                      <List
                                        sx={{
                                          padding: "0",
                                          display: "flex",
                                          gap: "15px",
                                          width: "100%",
                                          mt: "5px",
                                        }}
                                      >
                                        <ListItem>
                                          <CurrencyExchangeIcon />
                                          {translation?.PaymentRefID} :{" "}
                                          {session?.TransID}
                                        </ListItem>
                                      </List>
                                      <List
                                        sx={{
                                          padding: "0",
                                          display: "flex",
                                          gap: "15px",
                                          width: "100%",
                                          mt: "5px",
                                        }}
                                      >
                                        <ListItem>
                                          <AccessTimeIcon />
                                          {translation?.PaymentDate} :{" "}
                                          {new Date(
                                            session?.Date
                                          ).toLocaleTimeString()}
                                        </ListItem>
                                      </List>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="right_sect">
                                  <Box className="lbl_btn_flx">
                                    {session?.Status === "success" && (
                                      <Typography
                                        variant="span"
                                        className="lbl_tag lt-green"
                                      >
                                        {translation?.success}
                                      </Typography>
                                    )}
                                    {session?.Status === "Failed" && (
                                      <Typography
                                        variant="span"
                                        className="lbl_tag lt_red"
                                      >
                                        {translation?.Failed}
                                      </Typography>
                                    )}
                                    {session?.Status === "Pending" && (
                                      <Typography
                                        variant="span"
                                        className="lbl_tag lt_yellow"
                                      >
                                        {translation?.Pending}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                             
                                <Typography
                                  sx={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "1",
                                    color: "#000000",
                                    mt: "15px",
                                    ml: "2px",
                                  }}
                                >
                                  {session?.Amount} {session?.Currency}
                                </Typography>
                                <Box sx={{mt:'5px'}}>
                                  <button className="viewDetailsBtn"
                                  onClick={() =>
                                    window.open(session?.Invoice, "_blank")
                                  }
                                  >
                                    {translation?.Invoice}
                                  </button>
                                </Box>
                            </Box>
                          ))}
                        </Box>
                      </>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#727272",
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        {translation?.NoDataAvailable}
                      </Typography>
                    )}
                  </Box>
                </CustomTabPanel>
              </Box>
            )}
          </Box>

          <Advertisement />
        </Box>
      </Box>
      <VideoModal
        isOpen={isVideoModal}
        title={selectedClass?.SessionName}
        data={selectedClass}
        handleClose={handleClose}
      />
      <ZoomImageContainer />
      <FeedbackModal
        isOpen={feedbackModal}
        feedback={sessionFeedBack}
        translation={translation}
        onChangeFeedback={onChangeFeedback}
        onSubmitFeedback={onSubmitFeedback}
      />

      <FooterSection />
    </div>
  );
};
export default CoursesSchedules;
