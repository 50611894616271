import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import Header from "../header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import FooterSection from "../footer/FooterSection";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import "../../App.css";
import "../subject/Subject.css";
import "../session-list/SessionList.css";
import "../responsive/Responsive.css";
import teachinfopic from '../../assets/images/techPic1.png';
import hiretutor1 from '../../assets/images/onlineTutor1.png';
import hiretutor2 from '../../assets/images/onlineTutor2.png';
import hiretutor3 from '../../assets/images/onlineTutor3.png';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudentDashboard from '../../custom-hooks/useStudentDashboard';
import useHelperData from '../../custom-hooks/useHelperData';
import { getTopics } from '../../actions/quickCallsAction';
import { openMessageModal } from '../../actions/modalAction';


import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';

import MessageModal from '../common-components/MessageModal';
import Advertisement from '../common-components/Advertisement';
import HireTutorModal from '../common-components/HireTutorModal';
import FeedbackModal from '../common-components/FeedbackModal';
import { getStudentProfile } from '../../actions/studentProfileAction';
import generateAgoraToken from '../../services/AgoraTokenService';
import { encryptData } from '../../services/encryptDecrypt';

// const arr = [
//     hiretutor3,
//     hiretutor2,
//     hiretutor1,
// ]

const filterList = [
    {ID: 1, Value: 'Today'},
    {ID: 8, Value: 'Yesterday'},
    {ID: 2, Value: 'ThisWeek'},
    {ID: 3, Value: 'LastWeek'},
    {ID: 4, Value: 'ThisMonth'},
    {ID: 5, Value: 'LastMonth'},
    {ID: 6, Value: 'ThisYear'},
    {ID: 7, Value: 'LastYear'}
]

const FreeGroupClasses = () => {
    const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isConnected, socket } = useSocket();
	const translations = useTranslations();
	const [tutors, setTutors] = useState([]);
    const [selectedValue, setSelectedValue] = useState(1);


    const translation = {
        ...translations[0]?.Login,
        ...translations[8]?.PrivateClassDashboard,
        ...translations[7]?.PersonalInfo,
        ...translations[21]?.CommonFrontend,
        ...translations[23]?.TutorRegistration,
        ...translations[26]?.QuickCall,
        ...translations[29]?.FindTutor,
        ...translations[31]?.Wallet,
    }

    const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
	const privateTutorSessionID = sessionStorage.getItem('SessionID');
	const idToken = JSON.parse(Cookies.get('loginToken'))?.idToken;
    const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;

	const studentProfile = useSelector(({ studentProfileReducer }) => studentProfileReducer.student[0] || Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null);
    const studentDashboard = useSelector(({ studentDashboardReducer }) => studentDashboardReducer.dashboard || {});
    const [filteredData, setFilteredData] = useState([]);    
    console.log("filteredData",filteredData);
    

    const handleBack = () => {
        navigate(-1);
    }

    const viewTutorProfile = (tutor) => {
        navigate(`/tutor-profile/${btoa(tutor?.ID)}`);
    }
    
    const handleDateChange = (event) => {
        const selectedId = event.target.value;  
              
        setSelectedValue(selectedId);
    
        const now = new Date();
        const filtered = studentDashboard?.FreeSessionClass?.filter((item) => {
          const classDate = new Date(item.ClassData);
    
          switch (selectedId) {
            case 1: // Today
              return classDate.toDateString() === now.toDateString();
            case 8: // Yesterday
              const yesterday = new Date();
              yesterday.setDate(now.getDate() - 1);
              return classDate.toDateString() === yesterday.toDateString();
            case 2: // This Week
              const startOfWeek = new Date();
              startOfWeek.setDate(now.getDate() - now.getDay());
              startOfWeek.setHours(0, 0, 0, 0);
              return classDate >= startOfWeek;
            case 3: // Last Week
              const lastWeekStart = new Date();
              lastWeekStart.setDate(now.getDate() - now.getDay() - 7);
              lastWeekStart.setHours(0, 0, 0, 0);
              const lastWeekEnd = new Date(lastWeekStart);
              lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
              return classDate >= lastWeekStart && classDate <= lastWeekEnd;
            case 4: // This Month
              const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
              return classDate >= startOfMonth;
            case 5: // Last Month
              const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
              const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
              return classDate >= startOfLastMonth && classDate <= endOfLastMonth;
            case 6: // This Year
              const startOfYear = new Date(now.getFullYear(), 0, 1);
              return classDate >= startOfYear;
            case 7: // Last Year
              const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1);
              const endOfLastYear = new Date(now.getFullYear(), 0, 0);
              return classDate >= startOfLastYear && classDate <= endOfLastYear;
            default:
              return true;
          }
        });

        setFilteredData(filtered);
    };
      
    const joinClass = (tutor) => {
        const tutorResponse = {
            TutorID: tutor?.TutorID,
            TutorName: tutor?.TutorName,
            TutorImage: tutor?.TutorProfilePic,
            StudentID: profileInfo?.ID,
            StudentName: profileInfo?.UserName,
            StudentImage: profileInfo?.ProfilePictureUrl
        }
        sessionStorage.setItem('SessionID',tutor.SessionID );
        sessionStorage.setItem('pathName', window.location.pathname);
        sessionStorage.setItem("TutorResponse", JSON.stringify(tutorResponse));
        generateAgoraToken(profileInfo?.UID, 2, `channel${tutor?.SessionID}`).then((res) => {
            localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
            localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
            navigate(`/free-group-call/${encryptData(Number(tutor.SessionID))}`);
        });
    };
    

	useEffect(() => {
		if (studentProfile) {
			dispatch(getTopics({ GradeID: studentProfile?.StudentGradeID, ExamID: studentProfile?.StudentExaminationID }));
		}
	}, [studentProfile?.StudentGradeID, studentProfile?.StudentExaminationID])

    useEffect(() => {
        const handlePageRefreshOrClose = (event) => {
            const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
            const callRequestType = sessionStorage.getItem("CallRequestType");

            
            console.log("studentRequest",studentRequest);
            console.log("studentRequest>>>>>>>>>");
            
            if (studentRequest) {
                // onCancelToConnect();
            }
            
            if (callRequestType) {     
                // Prevent default unload
                event.preventDefault();
                event.returnValue = ""; 
            }
        };
    
        // Handle refresh (alternative to performance.navigation.type)
        if (window.performance?.getEntriesByType('navigation')[0]?.type === 'reload') {
            handlePageRefreshOrClose(new Event('beforeunload'));
        }

        if (socket) {
            console.log("socket",socket.connected);
            
            window.addEventListener('beforeunload', handlePageRefreshOrClose);
        } else{
            window.removeEventListener('beforeunload', handlePageRefreshOrClose);
        }
    
        // Add tab close listener
        // window.addEventListener('beforeunload', handlePageRefreshOrClose);
    
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handlePageRefreshOrClose);
        };
    }, [isConnected]);

    useEffect(() => {
        if (studentDashboard?.FreeSessionClass) {
            handleDateChange({ target: { value: 1 } });
        }
    }, [studentDashboard]); 
  return (
    <div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
      <Header/>
       
       <Box className="quikCallBanner">
       <Box className='OuterContainer' sx={{border:'none', borderRadius:'0px'}}>
            <Typography variant='h1'>
                {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
            </Typography>
        </Box>
       </Box>
        
   

        <Box className='OuterContainer containerShape HireTutorContainer' sx={{background:'#EAF4FF', padding:'30px'}}>
            <Box className='SectionIn'>
                <Box className="BackBtn">
                    <Button className='backBtn' variant="text" sx={{mb:2}}  startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.BackBtn} </Button>
                </Box>
                <Box className="hireTutorMain">
                  <Box className="outerCard" sx={{background:'#fff', borderRadius:'8px', padding:'16px'}}>
                    <Box className="SearchMain">
                       <Box className="fillterDropDown" sx={{gap:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                        <Box className="subjectFilter">
                        <p>{translation?.Date}</p>
                            <FormControl fullwidth>
                                <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedValue}
                                            onChange={handleDateChange}
                                        >
                                            <MenuItem value="0" disabled>
                                                {translation?.SelectDate}
                                            </MenuItem>
                                            { filterList?.map((item) => {
                                                return <MenuItem value={item?.ID} key={item?.ID}>{translation[item?.Value]}</MenuItem>
                                            }) }
                                </Select>
                            </FormControl>
                        </Box>
                        {/* <Box className="subjectFilter">
                        <p>{translation?.SelectTopic}</p>
                          <FormControl fullwidth>
                              <Select
                                labelId="demo-simple-select-label" 
                                id="demo-simple-select-day" 
                                value={selectedTopics}
                                // onChange={filterBySubjects}
                                >
                              <MenuItem value="0" disabled>{translation?.SelectTopics} {(isLoading && selectedTopics === '0') && <CircularProgress className="progress sub-progress" />}</MenuItem>
                                {topics?.map((item) => (
                                    <MenuItem key={item?.ID} value={item?.ID}>{item?.Value} {(isLoading && selectedTopics === item?.ID) && <CircularProgress className="progress sub-progress" />}</MenuItem>
                                ))}
                                <MenuItem value="all">{translation?.AllBtn} {(isLoading && selectedTopics === 'all') && <CircularProgress className="progress sub-progress" />}</MenuItem>
                              </Select>
                          </FormControl>
                        </Box> */}
                      
                        </Box>
                        {/* <Box className="subjectBtns" sx={{mt:1, gap:0.5, display:{xs: 'flex', alignItems: 'center'}}}>
                            <button className="selected">{translation?.Verified}</button>
                            <button>{translation?.TopRated}</button>
                            <button>{translation?.HighestSession}</button>
                            <button>{translation?.ByExperience}</button>
                        </Box> */}
                    </Box>
                  </Box>

                  <Box className="TutorListMain">
                    <Box className="TutorListOnline" sx={{mt:3, mb:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                      <Box className="mainHeading">
                        <h3>{translation?.ListOfClass}</h3>
                      </Box>
                      <Box className="onlineProfile"  sx={{ display:{xs: 'flex', alignItems: 'center'}}}>
                        {/* <Box  sx={{ display:{xs: 'flex', alignItems: 'center'}}}>
                            { Array.isArray(tutors) && tutors.slice(0, 3).reverse().map((tutor, index) => {
                                return  <Typography variant='div' key={index}>
                                    <img src={tutor?.ProfilePictureUrl}></img>
                                </Typography>
                            })
                            }
                        </Box> */}
                        {/* <h3>{translation?.TutorCount} {translation?.TutorsAreOnline}</h3> */}
                        {/* <h3>{tutors?.length} {translation?.TutorsAreOnline}</h3> */}
                      </Box>
                    </Box>
                    <Box className="outerCard">
                        {filteredData === undefined ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                        ) : filteredData?.length === 0 ? (
                        <Box
                            sx={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}
                        >
                            {translation?.NotutorsFound}
                        </Box>
                        ) : (
                            filteredData?.map((tutor, index) => (
                            <Box className="TutorAvailable" key={index}>
                            <Box
                                className="tutorAvailableIn tutorStatus"
                                sx={{ gap: '15px', display: { xs: 'flex' } }}
                            >
                                <Box
                                sx={{ cursor: 'pointer' }}
                                className="imageBox removeCircle"
                                // Uncomment when you define `viewTutorProfile`
                                // onClick={() => viewTutorProfile(tutor)}
                                >
                                <img src={tutor?.TopicImage} alt="Tutor" style={{width:'auto'}} />
                                </Box>
                                <Box className="sessionBox">
                                <Box className="contentBox" sx={{ mb: 1.5 }}>
                                    <Box className="content-in">
                                    <Typography
                                        variant="h3"
                                        sx={{
                                        color: '#005DB8',
                                        fontSize: '18px',
                                        display: { xs: 'flex', alignItems: 'center' },
                                        }}
                                    >
                                        {tutor?.ClassName}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                        color: 'black',
                                        fontSize: '10px',
                                        display: { xs: 'flex', alignItems: 'center' },
                                        }}
                                    >
                                        {tutor?.TopicName}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                        color: 'black',
                                        fontSize: '10px',
                                        display: { xs: 'flex', alignItems: 'center' },
                                        }}
                                    >
                                        {new Date(tutor.ClassData).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                        color: 'black',
                                        fontSize: '10px',
                                        display: { xs: 'flex', alignItems: 'center' },
                                        }}
                                    >
                                      {tutor?.TutorName}
                                    </Typography>
                                    </Box>
                                    <Box
                                    className="availableBtn"
                                    sx={{ gap: '10px', display: { xs: 'flex' } }}
                                    >
                                    {/* Uncomment and customize buttons as needed */}
                                    
                                    <button
                                        className={`${
                                            tutor?.ClassStatus === 2 
                                            ? 'darkBlue'
                                            : tutor?.ClassStatus === 1 ?
                                              'offlinebtn'
                                            : tutor?.ClassStatus === 3 ?
                                            'busynowBtn'
                                            : ''
                                        }`}
                                        disabled={
                                          tutor?.ClassStatus === 1 || tutor?.ClassStatus === 3
                                        }
                                        onClick={() => joinClass(tutor)}                                    >
                                      { 
                                         tutor?.ClassStatus === 2 
                                            ? translation?.StartClass
                                            : tutor?.ClassStatus === 1 ?
                                              translation?.ScheduleCourse
                                            : tutor?.ClassStatus === 3 ?
                                              translation?.CancelBtn
                                            : ''
                                       }
                                    </button>
                                   
                                    {/* <button className="bdrbtn" onClick={() => viewTutorProfile(tutor)}>
                                        {translation?.TutorProfile}
                                    </button> */}
                                    </Box>
                                </Box>
                                <Box className="progressNumber">
                                        <Typography variant='div' className='understood'> <Typography variant='div'>
                                            <svg width="15" height="15" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.76497 18.0241C14.3673 18.0241 18.0983 14.2931 18.0983 9.69075C18.0983 5.08838 14.3673 1.35742 9.76497 1.35742C5.1626 1.35742 1.43164 5.08838 1.43164 9.69075C1.43164 14.2931 5.1626 18.0241 9.76497 18.0241Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M8.09961 6.35742L13.0996 9.69075L8.09961 13.0241V6.35742Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </Typography>  {tutor?.Duration} Minutes</Typography>
                                        {/* <Typography variant='div' className='practicing'><Typography variant='div'>
                                            <svg width="16" height="14" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.0977 4.52539H2.76432C1.84385 4.52539 1.09766 5.27158 1.09766 6.19206V14.5254C1.09766 15.4459 1.84385 16.1921 2.76432 16.1921H16.0977C17.0181 16.1921 17.7643 15.4459 17.7643 14.5254V6.19206C17.7643 5.27158 17.0181 4.52539 16.0977 4.52539Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.7643 16.1914V2.85807C12.7643 2.41605 12.5887 1.99212 12.2762 1.67956C11.9636 1.367 11.5397 1.19141 11.0977 1.19141H7.76432C7.3223 1.19141 6.89837 1.367 6.58581 1.67956C6.27325 1.99212 6.09766 2.41605 6.09766 2.85807V16.1914" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            </Typography> {tutor?.TeachingExperience}+ {translation?.Years} {translation?.Experience}</Typography> */}
                                        <Typography variant='div' className='notStarted'><Typography variant='div'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,152ZM240,56H16a8,8,0,0,0-8,8V192a8,8,0,0,0,8,8H240a8,8,0,0,0,8-8V64A8,8,0,0,0,240,56ZM193.65,184H62.35A56.78,56.78,0,0,0,24,145.65v-35.3A56.78,56.78,0,0,0,62.35,72h131.3A56.78,56.78,0,0,0,232,110.35v35.3A56.78,56.78,0,0,0,193.65,184ZM232,93.37A40.81,40.81,0,0,1,210.63,72H232ZM45.37,72A40.81,40.81,0,0,1,24,93.37V72ZM24,162.63A40.81,40.81,0,0,1,45.37,184H24ZM210.63,184A40.81,40.81,0,0,1,232,162.63V184Z"/></svg>

                                        </Typography> <b className="moneyFont">{tutor?.Price} {tutor?.CurrencyType} </b>Free</Typography>
                                    </Box> 
                                    </Box>
                                </Box>
                            </Box>
                        ))
                        )}
                    </Box>
                    
                  </Box>

                  <Advertisement />
                  
                </Box>
            </Box>
        </Box>
        {/* <HireTutorModal isOpen={openModal} studentRequest={studentRequest} translation={translation} handleClose={onCancelToConnect} /> */}
        {/* <MessageModal isOpen={isMessage} btnTxt={translation?.OkButton} handleClose={onCallReject} />        */}
        {/* <FeedbackModal isOpen={feedbackModal} feedback={sessionFeedBack} translation={translation} onChangeFeedback={onChangeFeedback} onSubmitFeedback={onSubmitFeedback} /> */}
        <FooterSection/>
    </div>
  )
}

export default FreeGroupClasses